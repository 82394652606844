import React, { useState } from 'react'
import {useNavigate} from 'react-router-dom'

import { ReactComponent as VectorMenuIcon } from '../images/menu icon.svg'
import { ReactComponent as VectorKKCLogo } from '../images/TheKKC Logo.svg'

export default function Navbar() {
    const navigator = useNavigate()
    const [dropdown, setDropdown] = useState(false) 

    if (dropdown){
      const dropDown = document.getElementById('NavbarDropDown')
      dropDown.classList.add('active-dropdow')
      dropDown.style.opacity = 1;      

      (new Promise((res=>{setTimeout(res, 10)}))).then(()=>{
        document.addEventListener('click', ClearDropdown)
      })
    }

    function contactUsBtn(){
      if (window.location.href.split('/').slice(-1)[0] === 'Home'){
        window.scrollTo(0, document.body.scrollHeight);
      }
      
      else{
        navigator('/Home', { state: { scroll: 1 } })
      }
    }

    function ClearDropdown(itself){
      const dropDown = document.getElementById('NavbarDropDown')
      dropDown.classList.remove('active-dropdow')
      dropDown.style.opacity = 0
      document.removeEventListener('click', ClearDropdown)
      setDropdown(false)
    }



  return (
    <nav className='navbar break-4'>
        <VectorKKCLogo className='VectorKKCLogo'/>
        <div className='navbar-access'>
            <button className='btn-sec' onClick={()=>{navigator('/Home')}}>Home</button>
            <button className='btn-sec' onClick={()=>{navigator('/About-us')}}>About Us</button>
            <button className='btn-sec' onClick={()=>{navigator('/Services')}}>Services</button>
        </div>
        
        <button id='dropdownBtn' className='btn-sec VectorMenuIcon' onClick={()=>{setDropdown(!dropdown)}}>
          <VectorMenuIcon/>
          {}
          <div id='NavbarDropDown' className='navbar-dropdown'>
            <button className='btn-sec' onClick={()=>{navigator('/Home')}}>Home</button>
            <button className='btn-sec' onClick={()=>{navigator('/About-us')}}>About Us</button>
            <button className='btn-sec' onClick={()=>{navigator('/Services')}}>Services</button>
          </div>
        </button>

        <button className='btn-pr navbar-contact-btn' onClick={contactUsBtn}>Contact us</button>
    </nav>
  )
}
