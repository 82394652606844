import React from 'react'
import {useNavigate} from 'react-router-dom'

import { ReactComponent as VectorService1 } from '../images/404.svg'

export default function ErrorPage() {
    const navigator = useNavigate()
  return (
    <div className='root path404'>
        <VectorService1 className='svg'/>
        <h1 className='break-05'>Page Not Found</h1>
        <p className='break-2'>The page you are looking for doesnt exist, or has been deleted</p>
        <button className='btn-pr' onClick={()=>{navigator('/home')}}>Go Home</button>
    </div>
  )
}
