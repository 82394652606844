import React from 'react'

import Navbar from './Navbar'
import Footer from './Footer'
import { Outlet } from 'react-router-dom'

export default function Template() {


  return (
    <div className='root'>
        <Navbar/> 
        <Outlet/>
        <Footer/>
    </div>
  )
}
