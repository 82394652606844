import React from 'react'

import {useNavigate} from 'react-router-dom'
import { ReactComponent as VectorKKCLogo } from '../images/TheKKC Logo.svg'

export default function Footer() {
    const navigator = useNavigate()

    function contactUsBtn(){
      if (window.location.href.split('/').slice(-1)[0] === 'Home'){
        window.scrollTo(0, document.body.scrollHeight);
      }
      
      else{
        navigator('/Home', { state: { scroll: 'contact' } })
      }
    }

  return (
    <footer className='footer'>
    <VectorKKCLogo className='VectorKKCLogo break-2'/>

    <div className='footer-access break-4'>
      {window.location.href.split('/').slice(-1)[0] === 'Home'?
       <button className='btn-sec' onClick={()=>{window.scrollTo(0, 0)}}>Home</button>
       :
       <button className='btn-sec' onClick={()=>{navigator('/Home',  { state: { scroll: 'top' } })}}>Home</button>
      }
      <button className='btn-sec' onClick={()=>{navigator('/About-us')}}>About Us</button>
      <button className='btn-sec' onClick={()=>{navigator('/Services')}}>Services</button>
      <button className='btn-sec' onClick={contactUsBtn}>Contact</button>
    </div>
  </footer>
  )
}
