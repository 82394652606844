import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import reportWebVitals from './reportWebVitals';

//react dom
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

//comps
import HomePage from './components/HomePage';
import AboutUsPage from './components/AboutUsPage';
import ServicesPage from './components/ServicesPage'
import Template from './components/Template';
import ErrorPage from './components/ErrorPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
        <BrowserRouter>
        <Routes>
          <Route path='' element={<Template/>}>
            <Route path='Home' element={<HomePage/>} />
            <Route path='About-us' element={<AboutUsPage/>} />
            <Route path='Services' element={<ServicesPage/>} />
            <Route path="" element={<Navigate to="/home" replace/>}
            />
          </Route>
          <Route path='*' element={<ErrorPage/>}/>
        </Routes>
        </BrowserRouter>
  </React.StrictMode> 
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
