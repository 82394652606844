import React, { useEffect, useRef } from 'react'

import servicesVideo from '../images/Services-video.mp4'

export default function ServicesPage() {
  const sec1 = useRef()
  const sec2 = useRef()
  const sec3 = useRef()
  const sec4 = useRef()

  useEffect(()=>{
    window.scrollTo(0, 0);
    const observer = new IntersectionObserver((entires)=>{
      entires.forEach(en=>{
        if (en.isIntersecting){
          const locAddress = en.target.id.slice(-1);
          const links = document.getElementById('servicesAnchors').children
          for (let i=0; i<4; i++){
            links[i].classList.remove('highlighed-anchor')
          }
          console.log(locAddress)
          links[locAddress-1].classList.add('highlighed-anchor')
        }
      })
    })
    observer.observe(sec1.current)
    observer.observe(sec2.current)
    observer.observe(sec3.current)
    observer.observe(sec4.current)
  }, [])


  return (
    <div className='root'>
      <div className='service-page-intro break-2'>
        <h1>Our Services</h1>
        <p>We offer professional and efficient services across various areas to help businesses succeed and thrive</p>
      </div>


      <div className='intro-video break-8'>
          <video preload="none" autoPlay muted loop>
            <source src={servicesVideo} type="video/mp4"
            />
            Sorry, your browser doesn't support videos.
        </video>
      </div>

      <div className='services-list'>
        <div id='servicesAnchors' className='services-sidebar-access break-4'>
          <a href='#sec-1'>International Real Estate</a>
          <a href='#sec-2'>Technology solutions</a>
          <a href='#sec-3'>Consultancy and Development</a>
          <a href='#sec-4'>Advanced Engineering</a>
        </div>

        <div>
          <div className='service-info break-2'>
            <h1 ref={sec1} id='sec-1'>International Real Estate</h1>
            <p>KKC offers relocation and branching services for international organizations expanding into new markets. Our expert team provides research-backed tailored solutions to meet each client's unique needs, to eliminate disturbance and maximize efficiency. Relocation services include market research, legal compliance, and expert guidance.</p>
            <p>In coordination with our clients, we design packages that best fit their needs, these packages may include:</p>

            <ol>
              <li>Market research and analysis: Researching the local markets, identifying options for different financial plans, and negotiating rental agreements.</li>
              <li>Property management: After acquiring a property, KCC can offer professional property management solutions, including operation services, upkeep, maintenance, rent payment collections, and customer support.</li>
              <li>Property redesign and development: Our market search includes offering our vast Architectural expertise, to evaluate the existing building plans according to the required operating function and present developmental redesigns to completely replan and renovate the property to maximize the operating functions of the property. This service is a turn-key service, including all stages from design to execution.</li>
              <li>Moving assistance: Providing A to Z moving service, including packing, shipping and customs clearance if necessary.</li>
              <li>Orientation services: In some cases, orientation services is often provided to help clients adjust to their new location. This could involve providing information about the local area, including market analysis, transportation options, and cultural advisement.</li>
            </ol>      
          </div>

          <div className='service-info break-2'>
            <h1 ref={sec2} id='sec-2'>Technology Solutions</h1>
            <p>At KKC, we specialize in delivering cutting-edge web development, software design, and IT solutions to help businesses reach their full potential. With years of experience in the industry, we have developed a proven track record of providing high-quality services to clients across various industries.</p>
            
            <ol>
              <li><b>IT Consulting:</b>Our IT consulting services help businesses identify how technology can be used to optimize their operations, reduce costs, and increase efficiency.</li>
              <li><b>Cloud Services:</b>We offer cloud services that help businesses transition to cloud-based solutions to improve collaboration, scalability, and cost-effectiveness. Our cloud services include cloud migration, cloud infrastructure design and management, and cloud security and compliance.</li>
              <li><b>Cybersecurity:</b>With the increase in cyber threats, cybersecurity has become a critical component of business operations. We offer cybersecurity services that help businesses identify and address security risks, implement security measures, and respond to security incidents. Our cybersecurity services include network security, endpoint security, data encryption, and incident response. </li>
              <li><b>Data Analytics:</b>Our data analytics services help businesses make data-driven decisions by analyzing and interpreting large data sets. We use advanced analytics tools and techniques to identify trends, patterns, and insights that help businesses optimize their operations, reduce costs, and increase revenue. </li>
              <li><b>Software Design:</b>We provide end-to-end software design services, using industry-standard methodologies and the latest technologies to develop robust and scalable software solutions tailored to meet your business's specific needs. Our rigorous testing process ensures high quality and defect-free software, and our maintenance and support services ensure that your software remains secure, up-to-date, and running smoothly. </li>
              <li><b>Web Development:</b>We deliver custom web development solutions that meet and exceed our clients' expectations, from simple brochure websites to complex e-commerce platforms. We work with the top rating platforms including: Worldpress, Webflow, Shopify, Magneto..etc. </li>
            </ol>
          </div>
          
          <div className='service-info break-2'>
            <h1 ref={sec3} id='sec-3'>Consultancy and Development</h1>

            <ol>
              <li><b>Business and marketing development: </b>KKC's Business and Marketing Development services are designed to help our clients identify new opportunities and develop effective growth strategies. We recognize that every business is unique, and our team of experts is dedicated to providing customized solutions tailored to address your specific challenges and opportunities.Our comprehensive A-to-Z marketing strategy is specifically designed to help new businesses, individual brands, and startups achieve success. Our experienced consultants work closely with you to identify any market or corporate challenges, and develop effective solutions to overcome them.</li>
              <li><b>Business process outsourcing (BPO): </b>we understand that managing complex business functions can be challenging and time-consuming, which is why we offer Business Process Outsourcing (BPO) services. Our BPO services provide a cost-effective and efficient solution to managing various business functions, allowing our clients to focus on their core competencies. Our BPO services include finance and accounting, human resources, customer service, and more. With our expertise and industry experience, we are able to tailor our services to meet the specific needs of our clients, ensuring that they receive the best possible solutions for their business.</li>
              <li><b>Branding and personal branding: </b>KKC provides Branding and Personal Branding services, to help clients to develop strong and recognizable brands that resonate with their target audiences. KKC’s branding experts work closely with clients to develop brand strategies, identity and logo, content, and other elements that help to differentiate their businesses in competitive markets.</li>
              <li><b>Expert Training: </b>KKC understands the importance of providing high-quality training that meet the diverse needs of the clients. The training programs are designed to help organizations improve their skills, knowledge, and overall performance. KKC offers a range of training solutions, including customized training programs, online courses, and workshops. We provide post-training metric-based reports to help client measure the effectiveness of the trainings on the trainees.</li>
              <li><b>Product Design: </b>Product design and development services help clients create visually appealing and user-friendly products to ensure presenting innovative interface for your brand. These services cover all aspects of the design process, from conceptualization to final production, helping clients stand out in the marketplace and achieve their business objectives.</li>
            </ol>
          </div>

          <div className='service-info break-8'>
            <h1 ref={sec4} id='sec-4'>Advanced Engineering</h1>
            <p>We offer a wide range of engineering services, all compliant with international codes and standards, in the following areas:</p>
            
            <ol>
              <li><b>Civil and Structural Engineering: </b>We specialize in designing and constructing buildings, bridges, and other infrastructure projects. We offer comprehensive services that include design, consultation, research and development, project execution, and quality control, ensuring that every project we undertake is efficient, sustainable, and innovative.</li>
              <li><b>Mechanical and Electrical Engineering: </b>our team is dedicated to provide cutting-edge solutions for a variety of industries including power plants, renewable energy, electrical systems, from concept to commissioning. Our team of experts specializes in designing, testing, and implementing advanced systems and components.</li>
              <li><b>Environmental and Water Engineering: </b>we focus on developing sustainable solutions for the 21 century challenges. Our services include water and waste management, environmental impact assessment, pollution control, and climate change adaptation, all aimed at promoting sustainable development and preserving our planet for future generations. In addition, we specialize in designing and executing ground water exploration projects, coastal protection systems, and water structures.</li>
              <li><b>Architectural and Interior Engineering: </b>Our team brings a fresh and creative approach to the design of spaces that inspire, motivate, and engage. From concept to execution, we work closely with our clients to ensure that every project reflects their unique vision and brand identity, while adhering to the highest standards of quality and functionality.</li>
            </ol>
          </div>
        </div>
      </div>





    </div>
  )
}
