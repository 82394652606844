import React, { useEffect } from 'react'

import aboutUsVideo from '../images/About-us-video.mp4'

export default function AboutUsPage() {
    useEffect(()=>{
        window.scrollTo(0, 0);
    }, [])
  return (
    <div className='root'>
        <div className='about-us-intro break-2'>
            <h1>About Us</h1>
            <p>Our experienced team tailors solutions to our clients' unique needs, enabling them to achieve their goals and drive sustainable growth.</p>
        </div>
    
        <div className='intro-video break-4'>
            <video preload="none" autoPlay muted loop>
                <source src={aboutUsVideo} type="video/mp4"
                />
                Sorry, your browser doesn't support videos.
            </video>
        </div>

        <div className='about-us-outro break-8'>
            <h2>Welcome to KKC</h2>
            <p>We hold a strong belief that any business, irrespective of its size or industry, can flourish with the right guidance and strategic planning. We take immense pride in treating our clients' businesses as our own and working closely with them to comprehend their objectives and principles. With a team of seasoned professionals, we strive to build a sturdy bridge that leads our clients towards their envisioned success. Our approach is rooted in a deep understanding of our clients' unique needs and challenges, enabling us to tailor our solutions to their specific requirements. We are committed to delivering professional and efficient services that help our clients achieve their goals and drive sustainable growth.</p>
            <p>At KKC, we hold ourselves to the highest standards of quality and excellence in providing services to our clients. We understand that every business is unique, and that is why we approach each project with a keen attention to detail, making sure that our solutions far exceed expectations. We believe that our success is directly tied to our clients' success, and we are proud in being a trusted partner in your journey towards achieving your goals.</p>
        </div>
    </div>

    
  )
}
