import {React, useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import { ReactComponent as VectorService1 } from '../images/service 1.svg'
import { ReactComponent as VectorService2 } from '../images/service 2.svg'
import { ReactComponent as VectorService3 } from '../images/service 3.svg'
import { ReactComponent as VectorService4 } from '../images/service 4.svg'
import { ReactComponent as VectorContact1 } from '../images/contact1.svg'
import { ReactComponent as VectorContact2 } from '../images/contact2.svg'
import { ReactComponent as VectorContact3 } from '../images/contact3.svg'

import introVideo from '../images/intro-video.mp4'
import img_1 from '../images/img_1.webp'
import comp1 from '../images/comp1.webp'
import comp2 from '../images/comp2.webp'
import comp3 from '../images/comp3.webp'
import comp4 from '../images/comp4.webp'
import comp5 from '../images/comp5.webp'
import comp6 from '../images/comp6.webp'

export default function HomePage() {
  const navigator = useNavigate()
  const {state} = useLocation();
  
  useEffect(()=>{
    if (state){
      if (state.scroll === 'top'){
        window.scrollTo(0, 0);
      }

      else if (state.scroll === 1){
        window.scrollTo(0, 10);
        navigator('/home')
      }
    }
  }, [])

  return (
    <div className='root'>  
    
    <div className='intro break-4'>
      <h1>Simplicity and Impact.</h1>
      <p>We deliver a wide variety of professional and efficient services that help you achieve your business goals and drive sustainable growth.</p>
      <a className='btn-pr' href='#contactUs'>Contact Us</a>
    </div>

    <div className='intro-video break-8'>
        <video preload="none" autoPlay muted loop>
        <source src={introVideo} type="video/mp4"
        />
        Sorry, your browser doesn't support videos.
      </video>
    </div>
    

    <div className='trusted-section break-8'>
        <h2>Truested By</h2>

        <div className='trusted-logos'>
            <img loading="lazy" src={comp1}></img>
            <img loading="lazy" src={comp2}></img>
            <img loading="lazy" src={comp3}></img>
            <img  loading="lazy" src={comp4}></img>
            <img  loading="lazy" src={comp5}></img>
            <img  loading="lazy" src={comp6}></img>
        </div>
    </div>


    <div className='about-us break-8'>
      <div className='break-4'>
        <h1>About Us.</h1>
        <p>We believe that any business can thrive with the right guidance and planning. We take pride in treating our clients' businesses as our own and working closely with them. Our experienced team tailors solutions to our clients' unique needs, enabling them to achieve their goals and drive sustainable growth.</p>
        <button className='btn-pr' onClick={()=>{navigator('/about-us')}}> Learn More</button>
      </div>      
      <img  loading="lazy" src={img_1}/>
    </div>


    <div className='our-services break-8'>
      <h2>Our Services</h2>
      <p className='break-4'>We offer professional and efficient services across various areas to help businesses succeed and thrive</p>


      <div className='services'>

        <div className='service break-4'>
          <VectorService1 className='vector'/>
          <h3>International Real estate</h3>
          <p>We offer relocation and branching services for international organizations, including market research, legal compliance…etc.</p>
        </div>
        

        <div className='service break-4'>
          <VectorService2 className='vector'/>
          <h3>Technology solutions</h3>
          <p>We provides web development, software design, and IT solutions. Our experience across industries ensures high-quality services.</p>
        </div>
        

        <div className='service break-4'>
         <VectorService3 className='vector'/>
          <h3>Consultancy & Development</h3>
          <p>We offer consultancy in business and marketing, BPO, branding, expert training, and product development.</p>
        </div>
        

        <div className='service break-4'>
          <VectorService4 className='vector'/>
          <h3>Advanced Engineering</h3>
          <p>We offer a wide range of engineering services, all compliant with international codes and standards.</p>
        </div>
      </div>

      <button className='btn-pr' onClick={()=>{navigator('/services')}}> Learn More</button>
    </div>
    
    <div className='our-services break-8'>
      <h2 id='contactUs'>Contact Us</h2>
      <p className='break-4'>Reach out to us with any inquiries you have regarding your business</p>


      <div className='services'>
        <div className='service break-4'>
          <VectorContact1 className='vector'/>
          <h3>Email</h3>
          <p>We will reply in 1-3 business days</p>
          <a href='mailto:Info@thekkc.co'>Info@thekkc.co</a>
        </div>
        

        <div className='service break-4'>
          <VectorContact2 className='vector'/>
          <h3>Phone</h3>
          <p>Sun-Thu from 9am to 5pm</p>
          <a href='tel:+9647853145125'>+964 785 314 5125</a>
        </div>
        

        <div className='service break-4' style={{display:'None'}}>
          <VectorContact3 className='vector'/>
          <h3>Office</h3>
          <p>Pay us a visit at our office HQ</p>
          <a>Golan Center, Nergiz Plaza, Golan st.Erbil, KRI, Iraq</a>
        </div>
      </div>
    </div>

    </div> 
  )
}



